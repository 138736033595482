.pageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    height: 100vh;
    width: 100%;
    background-image: url("../../../../public/images/laptop-pencils-arrangement.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
