.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.w-chip {
  width: 100px;
}

.swal-wide {
  width: 60% !important;
  height: 90% !important;
}

.w3-table-all {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table
}

.w3-table-all {
  border: 1px solid #ccc
}

.w3-bordered tr,
.w3-table-all tr {
  border-bottom: 1px solid #ddd
}

.w3-striped tbody tr:nth-child(even) {
  background-color: #f1f1f1
}

.w3-table-all tr:nth-child(odd) {
  background-color: #fff
}

.w3-table-all tr:nth-child(even) {
  background-color: #f1f1f1
}

.w3-hoverable tbody tr:hover,
.w3-ul.w3-hoverable li:hover {
  background-color: #ccc
}

.w3-centered tr th,
.w3-centered tr td {
  text-align: center
}

.w3-table td,
.w3-table th,
.w3-table-all td,
.w3-table-all th {
  padding: 8px 8px;
  display: table-cell;
  text-align: left;
  vertical-align: top
}

#myDiv.fullscreen{
  z-index: 9999; 
  width: 100%; 
  height: 100%; 
  position: fixed; 
  top: 0; 
  left: 0; 
  bottom: 0;
  background-color: whitesmoke;
}
#myDiv.rescreen{

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}